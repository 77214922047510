.carousel-container {
    /* position: relative; */
    width: 100%;
    height: 32px;
    overflow: hidden;
    /* Hide overflow outside the container */
}

@media only screen and (max-width: 1024px) {
    .carousel-container {
        height: 30px;
    }
}

.carousel-slides {
    display: flex;
    flex-direction: column;
    /* gap: 10; */
}

.carousel-slide {
    font-size: 16px;
    height: 32px;
}

.slick-slide {
    height: 36px !important;
}

.page-slide .slick-slide {
    height: 24px !important;
}