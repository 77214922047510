#components-layout-demo-custom-trigger .trigger {
    padding: 0 24px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    -webkit-transition: color 0.3s;
    transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
    color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
    background: #fff;
}

.ant-layout-sider {
    min-width: 250px !important;
}

.logo {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 150px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 1.5rem auto 1.5rem;
}

.ant-layout-sider {
    background-color: #fff !important;
}

.my-3 {
    margin: 2rem 0;
}

.userdataContainer {
    -webkit-transition: ease all 0.5s;
    transition: ease all 0.5s;
}

.userdataContainer .item {
    border-bottom: 1px solid #ddd;
    padding: 15px 0;
}

.userdataContainer p {
    margin-bottom: 0;
}

.userdataContainer .table-avatar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.userdataContainer h2 {
    margin-bottom: 0;
}

.view-btn {
    cursor: pointer !important;
}

.card {
    border: 1px solid #ccc;
    border-radius: 8px;
    -webkit-box-shadow: 10px 8px 8px #ccc;
    box-shadow: 10px 8px 8px #ccc;
}

.card .pro-edit {
    position: absolute;
    right: 0;
    top: 0;
}

.card .pro-edit .edit-icon {
    background-color: #eee;
    border: 1px solid #e3e3e3;
    border-radius: 24px;
    color: #bbb;
    float: right;
    font-size: 12px;
    line-height: 24px;
    min-height: 26px;
    text-align: center;
    width: 26px;
    outline: none;
}

.card .pro-edit .edit-icon:hover {
    background-color: #1890ff;
    color: #fff;
}

.profile-view {
    position: relative;
}

.profile-view .profile-img-wrap {
    height: 100px;
    position: absolute;
}

.profile-view .profile-img-wrap .profile-img img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
}

.profile-view .card-title {
    color: #1f1f1f;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 20px;
}

.profile-view .profile-basic {
    margin-left: 140px;
    padding-right: 50px;
}

.profile-view .profile-basic .staff-id {
    font-size: 14px;
    font-weight: 600;
    margin-top: 5px;
}

.profile-view .profile-basic .profile-info-left {
    padding: 0 10px;
    border-right: 2px dashed #ccc;
}

.profile-view .profile-basic .personal-info {
    list-style: none;
    margin-bottom: 0;
    padding: 0;
}

.profile-view .profile-basic .personal-info li {
    margin-bottom: 10px;
}

.profile-view .profile-basic .personal-info li .title {
    color: #4f4f4f;
    float: left;
    font-weight: 500;
    margin-right: 30px;
    width: 25%;
}

.profile-view .profile-basic .personal-info li .text {
    color: #8e8e8e;
    display: block;
    overflow: hidden;
}

.profile-view .profile-basic .bio .download-btn {
    background-color: #0c2346;
    padding: 8px;
    color: #fff;
    text-align: center;
    margin-top: 2rem;
}

.profile-view .pro-edit {
    position: absolute;
    right: 0;
    top: 0;
}

.profile-view .pro-edit .edit-icon {
    background-color: #eee;
    border: 1px solid #e3e3e3;
    border-radius: 24px;
    color: #bbb;
    float: right;
    font-size: 12px;
    line-height: 24px;
    min-height: 26px;
    text-align: center;
    width: 26px;
    outline: none;
}

.profile-view .pro-edit .edit-icon:hover {
    background-color: #1890ff;
    color: #fff;
}

.certification-card-container .item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding: 15px 0;
}

.certification-card-container .item h4 {
    font-size: 17px;
    font-weight: 600;
    margin-top: 8px;
}

.certification-card-container .item .action-btn {
    background-color: #eee;
    border: 1px solid #e3e3e3;
    border-radius: 24px;
    color: #bbb;
    font-size: 12px;
    line-height: 24px;
    min-height: 26px;
    text-align: center;
    width: 26px;
    outline: none;
    margin: 0 6px;
}

.certification-card-container .item .action-btn:hover {
    background-color: #1890ff;
    color: #fff;
}

.certification-card-container p {
    margin-bottom: 0;
}

.certification-card-container .table-avatar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.certification-card-container h2 {
    margin-bottom: 0;
}

.ant-table-thead > tr > th {
    color: #0c2346;
    font-weight: 700;
}

.ant-menu-inline .ant-menu-item::after {
    border-right: 3px solid #0c2346;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    color: #fff !important;
    background-color: rgba(148, 164, 189, 0.8);
}

.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
    color: #fff;
}

.ant-modal-content {
    border-radius: 15px;
    overflow: hidden;
}

.ant-modal-content .ant-modal-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    /* padding-top: 1.6rem; */
}

.ant-modal-content .ant-modal-title {
    font-size: 22px;
}

.loading {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
    width: 70%;
    margin: 8rem auto;
}

.pphoto {
    width: 120px;
    height: 120px;
    border: 1px solid #1890ff;
    overflow: hidden;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
    margin: 10px auto 2rem;
}

.pphoto img {
    width: inherit;
    height: inherit;
    -o-object-fit: contain;
    object-fit: contain;
}

.pphoto span {
    position: absolute;
    bottom: 0;
    padding: 3px;
    left: 0;
    right: 0;
    text-align: center;
    background: var(--hr-blue);
    color: #fff;
}

.pphoto input[type='file'] {
    position: absolute;
    opacity: 0;
    z-index: 10;
}

.image-preview {
    position: relative;
    color: #fff;
    background: #017bb1;
    background-size: contain;
    border-color: var(--hr-blue);
    border: 1px solid var(--hr-blue);
    margin: 1rem auto;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 5px 15px;
}

.image-preview span {
    padding-right: 10px;
}

.image-preview input[type='file'] {
    position: absolute;
    opacity: 0;
    z-index: 10;
    cursor: pointer;
}

.image-preview h4 {
    font-size: 1.2em;
    padding-top: 17rem;
    color: #283cc8;
}

.merchant-data-tab {
    background-color: #fff;
    margin: 30px 0;
}

.ant-tabs-tab {
    padding: 20px 12px;
    text-transform: uppercase;
    font-weight: 600;
}

.merchant-pentest-table {
    background-color: #fff;
    margin: 15px 24px;
}

.merchant-pentest-table .header {
    font-size: 1.5rem;
    font-weight: 600;
    padding: 1rem;
    padding-bottom: 0rem;
    color: #000;
}

.user_info {
    list-style: none;
    width: 100%;
    margin: 0;
    padding: 0;
}

.user_info li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 1rem;
    border-top: 1px solid #ccc;
    color: #0c2346;
    border-bottom: 1px solid #ccc;
}

:not(.admin-dashboard) .ant-input,
:not(.ant-modal-root) .ant-input {
    /* border: 1px solid rgb(21, 22, 26); */
    border-radius: 4px;
}
.ant-input:focus,
.ant-input:hover,
.ant-input-focused {
    /* border-color: #0e2c66 !important; */
    box-shadow: 0 0 0 2px rgb(14 44 102 / 20%);
}
.ant-checkbox-input:focus,
.ant-checkbox-input:hover {
    border-color: #46649e !important;
}
.ant-checkbox-inner {
    border: 1px solid #15161a !important;
}
.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #0e2c66 !important;
    border-color: #0e2c66 !important;
}
@media (max-width: 35em) {
    header svg:first-of-type {
        z-index: 1000;
    }
    .ant-layout-sider {
        background-color: #fff;
        position: absolute;
        z-index: 999;
        width: 0px !important;
        -webkit-box-flex: 0 !important;
        -ms-flex: 0 0 0px !important;
        flex: 0 0 0px !important;
        max-width: 0px !important;
        min-width: 0px !important;
        display: none;
    }
    .ant-layout-sider-collapsed {
        display: block;
        width: 200px !important;
        -webkit-box-flex: 0 !important;
        -ms-flex: 0 0 200px !important;
        flex: 0 0 200px !important;
        max-width: 200px !important;
        min-width: 200px !important;
        height: 100vh !important;
    }
}

@media only screen and (max-width: 1024px) {
    .profile-view .profile-img-wrap {
        position: relative;
    }
    .profile-view .profile-img-wrap .profile-img {
        margin: 0 auto;
    }
    .profile-view .profile-basic {
        margin-left: 0;
        padding-right: 0;
        margin-bottom: 20px;
    }
    .profile-view .profile-basic .profile-info-left {
        border-right: none;
        padding: 20px 0;
        border-bottom: 2px dashed #ccc;
    }
}
