:root {
    --toastify-color-info: #0e2c66;
    --toastify-color-success: #046a45;
    --toastify-color-warning: #e2b208;
    --toastify-color-error: #d91b1b;
}

html,
body,
* {
    font-family: Poppins, sans-serif;
}

.primary_button {
    background: #1790cf;
    color: #fff;
}

textarea {
    text-align: inherit;
}

.auditor-compliance-tabs svg {
    color: #000;
    width: 15px !important;
    height: 15px !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

button {
    outline: none !important;
}

.ant-upload-list-item-error,
.ant-upload-list-item-error .ant-upload-text-icon > .anticon,
.ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon,
.ant-upload-list-item-error .ant-upload-list-item-name {
    color: #000 !important;
}

.view-modal-list-element {
    display: flex;
    align-items: center;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    margin-right: 2rem;
    border-bottom: 1px solid #ccc;
    justify-content: space-between;
    color: #333;
}

.view-modal-list-element:first-child {
    border-top: 1px solid #ccc;
}

.view-modal-list-element span {
    /* width: 50%;
    max-width: 250px; */
}

legend {
    width: 0 !important;
}

.auth-aside-design {
    background-image: linear-gradient(to bottom, #3b82f680, #3b82f680, #f1c40f29, #f1c40f29);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top;
}

.ant-btn-primary {
    @apply bg-sky-500;
}
.ant-upload-list-item-error {
    border-color: #d9d9d9 !important;
}

.ant-modal.title-start .ant-modal-content .ant-modal-header {
    justify-content: flex-start;
}
@media print {
    html,
    body {
        page-break-after: avoid !important;
        page-break-before: avoid !important;
        height: 100%; /* Use 100% here to support printing more than a single page*/
        margin: 0 !important;
        padding: 0 !important;
        overflow: hidden;
    }
    /* .print-display-none,
    .print-display-none * {
      display: none !important;
    }
    .print-visibility-hide,
    .print-visibility-hide * {
      visibility: hidden !important;
    } */
    #ciso-dashboard {
        visibility: visible !important;
        position: absolute;
        left: 0;
        top: 0;
    }
}

.custom-scroll::-webkit-scrollbar {
    width: 6px;
}

/* Track */
.custom-scroll::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.custom-scroll::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
.custom-scroll::-webkit-scrollbar-thumb:hover {
    background: #555;
}
.section_1[size="A4"][layout="portrait"] {
    width: 29.7cm;
    height: 21cm;  
    
  }
  @media print {
    body, page {
      margin: 0;
      box-shadow: 0;
    }
  }