/**
 * HTML5 / CSS3 Circle with Partial Border
 * http://stackoverflow.com/q/13059190/1397351
 */
$percentage: 83;
$percentageText: $percentage;

$arcThickness: 1em;
$arcColor: navy;
$arcColorBackground: #fff;

$circleSize: 1.2em;
$circleColor: #fff;

// preprocess numbers
$arcStartPosition: 90deg;

$circleOutterSize: $circleSize + $arcThickness * 2;

$flip: rotateX(0deg);
$_arcColor: $arcColor;
@if $percentage == 50 {
    $flip: rotateX(0deg);
}
@elseif $percentage > 50 {
    $flip: rotateX(180deg);
    $percentage: 100 - $percentage;

    $arcColor: $arcColorBackground;
    $arcColorBackground: $_arcColor;
}

$arcSkewX: ($percentage * 3.6 - 90) * -1deg;
$arcSkewXfix: $arcSkewX * -1;

// styles
* {
    margin: 0;
    padding: 0;
}
.circle {
    position: relative;
    margin: 10em auto;
    width: $circleOutterSize;
    height: $circleOutterSize;
    border-radius: 50%;
    background: $arcColorBackground;

    .text:after {
        content: $percentageText + '%';
        text-align: center;
        position: absolute;
        font-size: 12px;
        color: #ddd;
        height: 1em;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }
}
.circle:before {
    content: '';
    display: block;
    position: relative;
    width: $circleSize;
    height: $circleSize;
    border-radius: 50%;
    background: $circleColor;
    top: $arcThickness;
    left: $arcThickness;
}
.arc {
    overflow: hidden;
    width: 10%;
    height: 10%;
    //background: #333;
    position: absolute;
    top: -10000em;
    right: 50%;
    bottom: 50%;
    left: -10000em;
    padding: 10000em 0 0 10000em;
    transform-origin: 100% 100%;
    transform: rotateZ($arcStartPosition) $flip skewX($arcSkewX);
}
.arc:before {
    //box-sizing: border-box;
    display: block;
    border: solid $arcThickness $arcColor;
    width: 200%;
    height: 200%;

    box-sizing: border-box;
    border-radius: 50%;
    transform: skewX($arcSkewXfix);
    content: '';
}
.textbrandColor {
    color: #0e2c66;
}
.compliance_shadow {
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.08);
    border: 0.8px solid #c7c6ca;
    border-radius: 18.2857px;
}
._shadow_2 {
    background: #ffffff;
    box-shadow: 2px 2px 8px rgba(182, 182, 182, 0.15), -2px -2px 8px rgba(182, 182, 182, 0.15);
    border-radius: 8px;
}

//svg
.arc_circle {
    svg {
        height: 50px;
        margin: auto;
        display: block;
    }

    path {
        stroke-linecap: round;
        stroke-width: 5;
    }

    path.grey {
        stroke: lightgrey;
    }

    path.purple {
        stroke: purple;
        stroke-dasharray: calc(40 * 3.142 * 1.85);
        stroke-dashoffset: 20;
        /* adjust last number for variance */
    }
}

//setup card
.setup_card {
    display: flex;
    margin-bottom: 13px;
    align-items: center;
    background: #ffffff;
    box-shadow: -4px -4px 8px rgba(175, 172, 172, 0.15), 4px 4px 8px rgba(175, 172, 172, 0.15);
    border-radius: 16px;
}

.employee_card {
    .inner_tab {
        font-size: 13px;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &.active {
            background: #ffffff;
            box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.15);
            border-radius: 8px;
            transition: ease all;
        }
    }
}

.dash_tab_ {
    $sb-track-color: #fafafa;
    $sb-thumb-color: #5e5e62;
    $sb-size: 5px;
    .tab {
        color: #395ba9 Important !important;
        &.active {
            position: relative;
            &::after {
                content: '';
                position: absolute;
                border-bottom: 3px solid #0e2c66;
                bottom: -8px;
                right: 0;
                left: 0;
                transition: ease all 0.5s;
            }
        }
    }
}

.dash_tab_ {
    .tab_ {
        &.active {
            position: relative;
            //width: 100px;
            &::after {
                content: '';
                position: absolute;
                border-bottom: 2px solid #0e2c66;
                bottom: -10px;
                right: 0;
                left: 0;
                transition: ease all 0.5s;
            }
        }
    }
}

.alert_bg {
    background: #ffffff;
    box-shadow: 4px 4px 8px rgba(175, 172, 172, 0.15), -4px -4px 8px rgba(175, 172, 172, 0.15);
    border-radius: 14.23px;
}
.routing_bg {
    box-shadow: -111px 221px 99px rgba(230, 230, 230, 0.01), -62px 124px 83px rgba(230, 230, 230, 0.05),
        -28px 55px 62px rgba(230, 230, 230, 0.09), -7px 14px 34px rgba(230, 230, 230, 0.1),
        0px 0px 0px rgba(230, 230, 230, 0.1);
    border-radius: 14.23px;
}
.upgrade_bg {
    box-shadow: 0px 16px 24px 2px rgba(0, 0, 0, 0.14);
    border-radius: 4px;
}

.summary_shad {
    box-shadow: -189px 200px 110px rgba(209, 209, 209, 0.01), -106px 112px 93px rgba(209, 209, 209, 0.05),
        -47px 50px 69px rgba(209, 209, 209, 0.09), -12px 12px 38px rgba(209, 209, 209, 0.1),
        0px 0px 0px rgba(209, 209, 209, 0.1);
    border-radius: 8px;
}
:where(.css-dev-only-do-not-override-9ntgx0).ant-tour {
    z-index: 2000 !important;
}
.ant-tour {
    width: 445px;
}
.ant-tour-inner {
    background-color: #ffffff;
    width: 100%;
    padding: 10px;
    border-radius: 4px !important;
    box-shadow: none !important;

    .ant-tour-close {
        display: none !important;
    }
}

.ant-tour-header {
    display: flex;
    // justify-content: center;
    align-items: center !important;
    font-size: 16px !important;
    font-weight: 700;
    color: #0e2c66;
    width: 100%;
}

.ant-tour-title {
    font-size: 16px !important;
    font-weight: 700 !important;
    color: #0e2c66;
    width: 100%;
}

.ant-tour-description {
    font-size: 14px;
    font-weight: 400;
    color: #303034;
    margin-top: 5px;
    margin-bottom: 10px;
}

.ant-tour-buttons {
    display: flex;
    align-items: center;
}

.ant-tour-next-btn {
    background-color: #0e2c66 !important;
    color: #ffffff;
    // border-radius: 20px !important;
    border-radius: 4px !important;
    font-weight: 600;
    font-size: 12px !important;
    cursor: pointer;
    padding: 8px !important;
    // width: 80px !important;
    width: fit-content;
    height: 35px !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ant-tour-prev-btn {
    color: #0e2c66;
    font-weight: 600 !important;
    font-size: 16px !important;
    cursor: pointer;
    margin-right: 15px !important;
    outline: none !important;
    border: none !important;
}

.ant-tour-footer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.ant-tour-prev-btn:hover {
    color: #0e2c66 !important;
}

.exit-modal:hover {
    background-color: #d9d9d9;
}

.audit_title {
    padding: 16px;
    position: relative;
    color: #0e2c66;
    font-weight: 700;
    font-size: 18px;
    &:after,
    &:before {
        content: '';
        background: #b8bccc80;
        left: 0;
        right: 0;
        height: 2px;
        position: absolute;
        width: 100%;
        bottom: 0;
    }
    &:before {
        width: 30%;
        background: #0e2c66;
        height: 4px;
        z-index: 999;
    }
}
.parent_card:hover .child_card {
    display: 'flex';
}

.child_card {
    display: 'none';
}
