.filter {
    font-size: 0.875rem;
    margin-left: 5rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: black;
}

.auditor {
    max-height: 150px;
    min-height: 150px;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    overflow: hidden;
}

@media (max-width: 300em) {
    .filter {
        margin-left: 0rem;
    }
}
