.by3 {
    margin-top: 1.5rem;
    display: flex;
    /* align-items: center; */
    position: relative;
}
.by3 .first-line {
    height: 1.5px;
    width: 21px;
    background-color: #002c72;
}
.by3 .second-line {
    height: 25px;
    width: 1.5px;
    background-color: #002c72;
    position: absolute;
    bottom: -1px;
    left: 27px;
    transform: rotate(35deg);
    transform-origin: left;
}
.by3 .third-line {
    height: 25px;
    width: 1.5px;
    background-color: #002c72;
    position: absolute;
    bottom: -0.15rem;
    left: 40.5px;
    transform: rotate(-35deg);
    transform-origin: left;
}

.by3 .forth-line {
    height: 1.5px;
    width: 260px;
    margin-left: 1.7rem;
    background-color: #002c72;
}

.by5 {
    margin-top: 1.5rem;
    display: flex;
    /* align-items: center; */
    position: relative;
}
.by5 .first-line {
    height: 1.5px;
    width: 128px;
    background-color: #002c72;
}
.by5 .second-line {
    height: 25px;
    width: 1.5px;
    background-color: #002c72;
    position: absolute;
    bottom: -1px;
    left: 135px;
    transform: rotate(35deg);
    transform-origin: left;
}
.by5 .third-line {
    height: 25px;
    width: 1.5px;
    background-color: #002c72;
    position: absolute;
    bottom: -0.15rem;
    left: 149px;
    transform: rotate(-35deg);
    transform-origin: left;
}

.by5 .forth-line {
    height: 1.5px;
    width: 150px;
    margin-left: 1.7rem;
    background-color: #002c72;
}

.option1 {
    margin-top: 1.5rem;
    display: flex;
    position: relative;
}
.option1 .first-line {
    height: 1.5px;
    width: 21px;
    background-color: #002c72;
}
.option1 .second-line {
    height: 25px;
    width: 1.5px;
    background-color: #002c72;
    position: absolute;
    bottom: -1px;
    left: 27px;
    transform: rotate(35deg);
    transform-origin: left;
}
.option1 .third-line {
    height: 25px;
    width: 1.5px;
    background-color: #002c72;
    position: absolute;
    bottom: -0.15rem;
    left: 40.5px;
    transform: rotate(-35deg);
    transform-origin: left;
}

.option1 .forth-line {
    height: 1.5px;
    width: 300px;
    margin-left: 1.7rem;
    background-color: #002c72;
}

.option2 {
    margin-top: 1.5rem;
    display: flex;
    position: relative;
}
.option2 .first-line {
    height: 1.5px;
    width: 118px;
    background-color: #002c72;
}
.option2 .second-line {
    height: 25px;
    width: 1.5px;
    background-color: #002c72;
    position: absolute;
    bottom: -1px;
    left: 125px;
    transform: rotate(35deg);
    transform-origin: left;
}
.option2 .third-line {
    height: 25px;
    width: 1.5px;
    background-color: #002c72;
    position: absolute;
    bottom: -0.15rem;
    left: 139px;
    transform: rotate(-35deg);
    transform-origin: left;
}

.option2 .forth-line {
    height: 1.5px;
    width: 200px;
    margin-left: 1.7rem;
    background-color: #002c72;
}

.option3 {
    margin-top: 1.5rem;
    display: flex;
    position: relative;
}
.option3 .first-line {
    height: 1.5px;
    width: 210px;
    background-color: #002c72;
}
.option3 .second-line {
    height: 25px;
    width: 1.5px;
    background-color: #002c72;
    position: absolute;
    bottom: -1px;
    left: 217px;
    transform: rotate(35deg);
    transform-origin: left;
}
.option3 .third-line {
    height: 25px;
    width: 1.5px;
    background-color: #002c72;
    position: absolute;
    bottom: -0.15rem;
    left: 231px;
    transform: rotate(-35deg);
    transform-origin: left;
}

.option3 .forth-line {
    height: 1.5px;
    width: 110px;
    margin-left: 1.7rem;
    background-color: #002c72;
}



.option4 {
    margin-top: 1.5rem;
    display: flex;
    position: relative;
}
.option4 .first-line {
    height: 1.5px;
    width: 299px;
    background-color: #002c72;
}
.option4 .second-line {
    height: 25px;
    width: 1.5px;
    background-color: #002c72;
    position: absolute;
    bottom: -1px;
    left: 305px;
    transform: rotate(35deg);
    transform-origin: left;
}
.option4 .third-line {
    height: 25px;
    width: 1.5px;
    background-color: #002c72;
    position: absolute;
    bottom: -0.15rem;
    left: 319px;
    transform: rotate(-35deg);
    transform-origin: left;
}

.option4 .forth-line {
    height: 1.5px;
    width: 20px;
    margin-left: 1.7rem;
    background-color: #002c72;
}