@tailwind base;
@tailwind components;
@tailwind utilities;

.custom-table-row-width {
    max-width: 5vw;
}

@media (min-width: 1366px) {
    .custom-table-row-width {
        max-width: 8vw;
    }
}

.scan-body-elements {
    display: flex;
    flex-direction: column;
    padding: 4px 24px;
    color: #64748b;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

.scan-body-elements.brief {
    padding: 0;
}

.scan-body-elements.brief ul li {
    display: none;
}

.scan-body-elements.brief ul li:nth-of-type(1),
.scan-body-elements.brief ul li:nth-of-type(2) {
    display: list-item;
}

.scan-body-elements ul {
    display: flex;
    flex-direction: column;
    list-style-type: disc;
    padding-left: 24px;
}

.scan-body-elements div {
    display: flex;
    flex-direction: column;
}

.custom-scroll::-webkit-scrollbar {
    width: 8px;
}
.custom-scroll::-webkit-scrollbar-track {
    background-color: transparent;
}
.custom-scroll::-webkit-scrollbar-thumb {
    /* background-color: theme.palette.primary[900] + 30, */
    border-radius: 10px;
}
