.active_pagination {
    background-color: #0e2c66;
    color: #ffffff;
    border-radius: 3px;
    margin-right: 0.5rem;
    min-width: 35px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    font-weight: 700;
}

.answered {
    color: #0e2c66;
    border: 2px solid #06b877;
    border-radius: 3px;
    margin-right: 0.5rem;
    min-width: 35px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 13px;
    cursor: pointer;
}

.not_answered {
    color: #0e2c66;
    border: 2px solid #ff5449;
    border-radius: 3px;
    margin-right: 0.5rem;
    min-width: 35px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 13px;
    cursor: pointer;
}
.assigned {
    color: #121446ed;
    border: 2px solid #b28c00;
    border-radius: 3px;
    margin-right: 0.5rem;
    min-width: 35px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 13px;
    cursor: pointer;
}

.neutral {
    color: #0e2c66;
    border: 2px solid #e1e2ec;
    border-radius: 3px;
    margin-right: 0.5rem;
    width: 35px;
    height: 32px;
    display: flex;
    justify-content: center;
    font-weight: 700;
    font-size: 13px;
    cursor: pointer;
}

.active_anchor {
    border: 2px solid #0e2c66;
    color: #0e2c66;
    border-radius: 3px;
    margin-right: 0.5rem;
    width: 35px;
    height: 32px;
    display: flex;
    justify-content: center;
    font-weight: 700;
    font-size: 13px;
    cursor: pointer;
}
