.auditor-pool-header__logo {
    max-width: 100px;
}

.auditor-pool-header__logo svg {
    max-width: 100%;
}

.auditor-pool-header__sm-screen-menu .MuiPaper-root {
    width: 100%;
}

.auditor-pool-header__sm-screen-menu .MuiPaper-root .menu-heading {
    margin: 0;
}

.auditor-pool-header__sm-screen-menu .MuiPaper-root nav .MuiMenuItem-root {
    color: #0e2c66;
}

.auditor-pool-header__sm-screen-menu .MuiPaper-root nav .MuiMenuItem-root .MuiTypography-root {
    font-weight: 500;
}

.auditor-pool-header__sm-screen-menu .MuiPaper-root nav .button-container {
    padding: 0.4rem 1rem;
    display: block;
    margin-bottom: 1.5rem;
}

.auditor-pool-header__sm-screen-menu .MuiPaper-root nav .button-container:hover {
    color: #fff;
}

.auditor-pool-header .auditor-pool-appbar {
    background-color: #fff;
    -webkit-box-shadow: 0px 4px 8px 0px #00000026;
    box-shadow: 0px 4px 8px 0px #00000026;
}

.auditor-pool-header .auditor-pool-filter .filters h5 {
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 0.5rem;
}

.auditor-pool-header .auditor-pool-filter .filters .MuiFormControl-root label {
    -webkit-transform: translate(14px, 10px) scale(1);
    transform: translate(14px, 10px) scale(1);
}

.auditor-pool-header .auditor-pool-filter .filters .MuiFormControl-root label.MuiInputLabel-shrink {
    -webkit-transform: translate(14px, -9px) scale(0.75);
    transform: translate(14px, -9px) scale(0.75);
}

.auditor-pool-header .auditor-pool-filter .filters .MuiFormControl-root .MuiOutlinedInput-root input {
    padding: 0.6rem;
    width: 70px;
    -webkit-transition: width 0.3s;
    transition: width 0.3s;
}

.auditor-pool-header .auditor-pool-filter .filters .MuiFormControl-root .MuiOutlinedInput-root input:focus {
    width: 200px;
}

.auditor-pool-header .auditor-pool-filter .filters .MuiFormControl-root .MuiOutlinedInput-root .MuiSelect-select {
    padding: 0.6rem;
    padding-right: 2rem;
}

.auditor-pool-header .auditor-pool-filter .search-bar .MuiInput-root input {
    color: #000;
}

@media screen and (min-width: 768px) {
    .auditor-pool-header__logo {
        max-width: 180px;
    }
    .auditor-pool-header .css-1rujksk-MuiContainer-root {
        padding: 1rem 0 2rem;
    }
    .auditor-pool-header .top-header nav,
    .auditor-pool-header .top-header ul {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
    .auditor-pool-header .top-header nav .MuiMenuItem-root,
    .auditor-pool-header .top-header ul .MuiMenuItem-root {
        color: #0e2c66;
        margin-right: 1rem;
    }
    .auditor-pool-header .top-header nav .MuiMenuItem-root .MuiTypography-root,
    .auditor-pool-header .top-header ul .MuiMenuItem-root .MuiTypography-root {
        font-weight: 500;
    }
    .auditor-pool-header .top-header nav .MuiMenuItem-root .MuiListItemIcon-root,
    .auditor-pool-header .top-header ul .MuiMenuItem-root .MuiListItemIcon-root {
        display: none;
    }
}

.auditor-pool__body {
    margin-top: 2rem;
    padding-top: 15rem;
}

.auditor-pool__body .MuiGrid-item .MuiCard-root {
    -webkit-box-shadow: 0px 8px 16px 0px #00000033;
    box-shadow: 0px 8px 16px 0px #00000033;
    border-radius: 16px;
}

.auditor-pool__body .MuiGrid-item .MuiCard-root .MuiCardHeader-root {
    color: #000;
}

.auditor-pool__body .MuiGrid-item .MuiCard-root .MuiCardHeader-root .MuiCardHeader-content {
    width: calc(100% - 56px);
}

.auditor-pool__body .MuiGrid-item .MuiCard-root .MuiCardHeader-root .MuiCardHeader-content .MuiCardHeader-title {
    font-weight: 600;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

.auditor-pool__body .MuiGrid-item .MuiCard-root .MuiCardHeader-root .MuiCardHeader-content .MuiCardHeader-subheader {
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    font-weight: 400;
}

.auditor-pool__body .MuiGrid-item .MuiCard-root .MuiCardContent-root {
    color: #000;
}

.auditor-pool__body .MuiGrid-item .MuiCard-root .MuiCardContent-root .list-item {
    font-family: 15px;
    font-weight: 400;
    text-transform: capitalize;
}

.auditor-pool__body .MuiGrid-item .MuiCard-root .MuiCardContent-root .list-item .value {
    font-weight: 500;
}

.auditor-pool__body .MuiGrid-item .MuiCard-root .MuiCardActions-root {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.auditor-pool__body .MuiGrid-item .MuiCard-root .MuiCardActions-root a {
    color: #0e2c66;
    font-weight: 600;
    text-transform: capitalize;
}

@media screen and (max-width: 767px) {
    .auditor-pool__body {
        padding-top: 5rem;
        padding-bottom: 2rem;
    }
}

@media screen and (min-width: 768px) {
    .auditor-pool .MuiContainer-root {
        max-width: 85%;
    }
}

.one-auditor-view .section-body {
    background-color: #fff;
}

.one-auditor-view__body {
    padding-top: 10rem;
    padding-bottom: 2rem;
    min-height: 100vh;
}

.one-auditor-view__body .section-card-container {
    margin-bottom: 2rem;
}

.one-auditor-view__body .section-card-container .section-card-heading {
    font-size: 1.125rem;
    font-weight: 500;
}

.one-auditor-view__body .section-card-container .section-card {
    border-radius: 0.5rem;
    border: 1px solid #0e2c66;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 1rem;
}

.one-auditor-view__body .section-card-container .section-card.personality-card .personal-info h1 {
    color: #0e2c66;
    font-size: 1.5rem;
    font-weight: 600;
}

.one-auditor-view__body .section-card-container .section-card.personality-card .personal-info .bio-container {
    margin: 0.5rem 0 1.5rem;
    font-size: 1rem;
}

.one-auditor-view__body .section-card-container .section-card.personality-card .personal-info .bio-container span {
    color: #9c9c9c;
    text-transform: capitalize;
}

.one-auditor-view__body .section-card-container .section-card.personality-card .personal-info .bio-container article {
    color: #000;
    font-size: 0.9rem;
}

.one-auditor-view__body .section-card-container .section-card.personality-card .personal-info .buttons {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (1fr) [2];
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
}

.one-auditor-view__body .section-card-container .section-card.personality-card .personal-info .buttons button {
    text-transform: capitalize;
}

.one-auditor-view__body .section-card-container .section-card.personality-card .contact-info .list-item {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 120px auto;
    grid-template-columns: 120px auto;
    color: #000;
    margin-bottom: 0.7rem;
}

.one-auditor-view__body .section-card-container .section-card.personality-card .contact-info .list-item .label {
    text-transform: capitalize;
}

.one-auditor-view__body .section-card-container .section-card.personality-card .contact-info .list-item .value {
    font-weight: 500;
}

.one-auditor-view__body .section-card-container .section-card.personality-card .contact-info .list-item .value a {
    color: #000;
}

.one-auditor-view__body .section-card-container .section-card.cert-license-card .MuiList-root {
    padding: 0;
}

.one-auditor-view__body .section-card-container .section-card.cert-license-card .MuiList-root .cert-list-item {
    color: #000;
}

.one-auditor-view__body
    .section-card-container
    .section-card.cert-license-card
    .MuiList-root
    .cert-list-item
    .MuiListItemText-primary
    p {
    font-weight: 500;
}

.one-auditor-view__body
    .section-card-container
    .section-card.cert-license-card
    .MuiList-root
    .cert-list-item
    .MuiListItemText-secondary {
    color: #000;
}

.one-auditor-view__body .section-card-container .section-card.portfolio-card .MuiImageList-root {
    margin: 1rem;
}

.one-auditor-view__body
    .section-card-container
    .section-card.portfolio-card
    .MuiImageList-root
    .MuiImageListItemBar-titleWrap {
    padding-bottom: 0;
}

.one-auditor-view__body
    .section-card-container
    .section-card.portfolio-card
    .MuiImageList-root
    .MuiImageListItemBar-titleWrap
    .MuiImageListItemBar-title {
    color: #000;
    font-weight: 500;
}

.one-auditor-view .divider {
    border-color: #9c9c9c;
}

.one-auditor-view .divider.vertical {
    min-height: 150px;
}
