.rectangle {
    flex: 1;
    height: 20px;
}

.rectangle-container {
    display: flex;
    flex-direction: column;
    width: 630px;
    gap: 0.5px;
}
.rectangle_row .legend {
    width: 50px;
    text-align: left;
    color: #46464a;
    font-size: 10px;
    font-weight: 600;
    align-self: center;
}

.rectangle_row .impact {
    flex: 1;
    text-align: center;
    color: #46464a;
    font-size: 10px;
    font-weight: 600;
    margin-top: 0.5rem;
}

.rectangle_row {
    display: flex;
    flex-direction: row;
    gap: 0.5px;
}

.rectangle1 {
    flex: 1;
    height: 50px;
}

.rectangle-container1 {
    display: flex;
    width: 530px;
    flex-direction: column;
    gap: 0.5px;
}
.rectangle_row1 .legend {
    width: 70px;
    text-align: left;
    color: #46464a;
    font-size: 10px;
    font-weight: 600;
    align-self: center;
}
.rectangle_row1 .impact {
    flex: 1;
    text-align: center;
    color: #46464a;
    font-size: 10px;
    font-weight: 600;
    margin-top: 0.5rem;
}

.rectangle_row1 {
    display: flex;
    flex-direction: row;
    gap: 0.5px;
}

.line-element::before {
    content: '';
    position: absolute;
    top: -5px;
    left: -10px;
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 10px solid #ffffff;
    animation-name: arrow;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

@keyframes arrow {
    0% {
        border-right: 10px solid #ffffff;
    }
    25% {
        border-right: 10px solid #AAA8A8;
    }
}

@keyframes line {
    0% {
        background-color: #ffffff;
    }
    25% {
        background-color: #AAA8A8;
    }
}
